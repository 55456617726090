import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  HostBinding,
  inject,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ToastModule } from '@sitemule/ng-components/components/toast';
import { getWindow } from 'ssr-window';
import { SliderComponent } from './components/slider/slider.component';
import { VerticalTextSliderComponent } from './components/vertical-text-slider/vertical-text-slider.component';
import { SitemuleAIService } from './services/sitemule-ai.service';
import { UserService } from './services/sitemule-user.service';

const win = getWindow();

const isSameDomain = (url1: string) => {
  return url1.startsWith(`${location.protocol}//${location.host}`);
};
const getRelativePath = (url1: string) => {
  return url1.replace(`${location.protocol}//${location.host}`, '');
};
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ToastModule, SliderComponent, VerticalTextSliderComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly sitemuleAIService = inject(SitemuleAIService);
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  private readonly el = inject(ElementRef);

  private readonly isBrowser = isPlatformBrowser(this.platformId);
  public readonly assistantStatus = this.sitemuleAIService.assistantStatus;
  public readonly suggestions = this.sitemuleAIService.suggestions;
  public readonly assistantIconExpanded = signal(true);

  public readonly width = computed(() => {
    const suggestions = this.suggestions();
    return `${Math.max(...suggestions.map(s => s.length)) * 8}px`;
  });

  private readonly eff1 = effect(() => {
    const assistantStatus = this.assistantStatus();

    if (!this.isBrowser) {
      return;
    }

    const classNames = Array.from(win.document.body.classList);
    const className = classNames.find(c => c.startsWith('assistant-status-'));

    if (className) {
      win.document.body.classList.remove(className);
    }

    win.document.body.classList.add(`assistant-status-${assistantStatus}`);

    setTimeout(() => {
      win.dispatchEvent(new Event('resize'));
    }, 250); // 250ms has to corelate with the animation length
  });
  private readonly eff2 = effect(() => {
    const loggedInUser = this.userService.loggedInUser();

    if (!this.isBrowser) {
      return;
    }

    this.sitemuleAIService.destroy().then(() => {
      if (loggedInUser && loggedInUser.roles.find(r => r.key === 'web-management')) {
        this.sitemuleAIService.init(this.el.nativeElement.querySelector('.assistant')).then(() => {
          this.sitemuleAIService.onMessageLinkClick((data: { link: string; label: string }) => {
            if (isSameDomain(data.link)) {
              this.router.navigateByUrl(getRelativePath(data.link));
            } else {
              win.open(data.link);
            }
          });
        });
      }
    });
  });

  @HostBinding('class') public get getHostClass() {
    return `assistant-status-${this.assistantStatus()}`;
  }

  public expandSmall() {
    this.sitemuleAIService.expandSmall();
  }

  public addSuggestion(message: string) {
    this.sitemuleAIService.showChatView();
    this.sitemuleAIService.sendMessage(message);
  }

  public assistantMaskClick() {
    this.sitemuleAIService.showIcon();
  }
}
